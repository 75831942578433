#page-benefits {
    #benefits-hero {
        min-height: 250px;

        .image-overlay {
            opacity: 0.2;
        }

        img {
            object-position: center center;
        }
    }

    #service-advantages {
        .image-overlay {
            opacity: 0;
        }

        img {
            object-position: top center;
        }
    }

    #exclusive-concept {
        .image-overlay {
            opacity: 0.2;
        }

        img {
            object-position: bottom center;
        }
    }

    .comparison-item {
        > div {
            width: 100%;
            max-width: 220px;
        }

        ul {
            margin-bottom: 0;

            li {
                line-height: 1.5;

                &::marker {
                    font-size: 0.5rem;
                    line-height: 1;
                }
            }
        }
    }

    .table-benefits {
        --table-border-width: 1px;

        thead {
            th {
                font-size: 0.875rem;
                font-weight: normal;
                text-transform: none;
                color: $body-color;
            }

            th.rotate {
                vertical-align: bottom;
                position: relative;
                width: 2.5rem;

                > div {
                    display: flex;
                    width: 2.5rem;
                    white-space: nowrap;
                    text-align: right;
                    justify-content: center;

                    > span {
                        writing-mode: vertical-lr;
                        white-space: nowrap;
                        text-align: right;

                        svg {
                            transform: translateY(-41px) rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}
