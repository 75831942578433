#page-pricing {
    .pricing {
        font-weight: bold;
        font-size: 2rem;

        .discounted-price {
            margin-left: 0.25rem;
            font-size: 1.25em;
        }

        .original-price {
            position: relative;

            &::before {
                content: '';
                top: 50%;
                background: currentColor;
                width: 110%;
                position: absolute;
                height: 0.1em;
                border-radius: 0.1em;
                left: -5%;
                white-space: nowrap;
                display: block;
                transform: rotate(-15deg);
            }
        }
    }

    #pricing-hero {
        min-height: 750px;

        .image-overlay {
            opacity: 0.33;
        }

        img {
            object-position: center center;
        }
    }
}
