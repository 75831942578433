#page-functioning {
    #functioning-hero {
        min-height: 250px;

        .image-overlay {
            opacity: 0.2;
        }

        img {
            object-position: bottom center;

            @include media-breakpoint-up(lg) {
                object-position: center center;
            }

            @include media-breakpoint-up(xl) {
                object-position: top center;
            }
        }
    }
}
