@import 'core';

// Fonts
@import '@fontsource/parisienne';

@import 'pages/content/benefits';
@import 'pages/content/home';
@import 'pages/content/functioning';
@import 'pages/content/pricing';

.page-content {
    --#{$prefix}body-font-size: 1rem;
    --#{$prefix}body-bg: white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-weight: 500;
    }

    .nav-link {
        text-transform: uppercase;
        align-items: baseline;
    }

    .fw-normal strong {
        font-weight: inherit;
    }

    section {
        padding: 0.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 1rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }
    }

    .form-control {
        --#{$prefix}border-color: #{$body-color};
    }

    .card-feature {
        --#{$prefix}card-bg: #{$body-bg};

        .card-number {
            font-size: 1.5rem;
            position: relative;
            top: -1rem;
            left: -0.5rem;
            pointer-events: none;
        }
    }

    .btn-cta {
        white-space: wrap;
        font-weight: normal;
    }

    .font-alternate {
        font-family: 'Parisienne', cursive;
        font-weight: 400;
        font-style: normal;

        strong {
            font-weight: inherit;
        }
    }

    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .variant-light {
        .image-overlay {
            background: #000;
            opacity: 0.5;
        }
    }
}
